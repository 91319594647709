import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

const archiveWrapper = document.querySelector(".store__wrapper");
const archiveContainers = gsap.utils.toArray(".rows-reveal"); // Containers for each category
const archiveItems = gsap.utils.toArray(".store__row > .store__item"); // Individual items
const filterText = document.querySelector(".store__filter-text");

if (archiveItems) {
    const filterButtons = [...document.querySelectorAll(".store__button")];

    const filterCategory = (category, clickedButton) => {
        // Reset all buttons and set the clicked button as active
        filterButtons.forEach((button) => button.classList.remove("active"));
        clickedButton.classList.add("active");

        // Handle "all" selection specifically
        if (category === "all") {
            console.log("Showing all items"); // Debug log for "all"
            
            // Remove 'hidden' class from all containers and items
            archiveContainers.forEach(container => container.classList.remove("hidden"));
            archiveItems.forEach(item => item.classList.remove("hidden"));

            // Ensure archiveWrapper is visible and filterText is hidden
            archiveWrapper.style.display = "block";
            filterText.style.display = "none";

            return; // Exit the function here to prevent further filtering
        }

        console.log("Filtering for category:", category); // Debug log for category

        // Hide all containers and items by adding the 'hidden' class
        archiveContainers.forEach(container => container.classList.add("hidden"));
        archiveItems.forEach(item => item.classList.add("hidden"));

        // Determine which items to show based on selected category
        const filteredItems = archiveItems.filter(item => item.getAttribute("data-category") === category);

        // Show a message if there are no matching items
        if (!filteredItems.length) {
            console.log("No items found for category:", category); // Debug log for no items found
            archiveWrapper.style.display = "none";
            filterText.style.display = "block";
        } else {
            console.log("Items found for category:", category); // Debug log for items found
            archiveWrapper.style.display = "block";
            filterText.style.display = "none";

            // Show only the container(s) and items for the selected category
            archiveContainers.forEach(container => {
                if (container.getAttribute("data-category") === category) {
                    container.classList.remove("hidden");
                }
            });
            filteredItems.forEach(item => item.classList.remove("hidden"));
        }
    };

    // Attach event listeners
    filterButtons.forEach((button) => {
        const category = button.getAttribute("data-category");

        button.addEventListener("click", () => {
            filterCategory(category, button);
        });
    });

    // Set default to "all" to display all items initially
    const defaultCategory = "all";
    const defaultButton = filterButtons.find((button) => button.getAttribute("data-category") === defaultCategory);
    if (defaultButton) {
        defaultButton.click();
    }
}